import React, { Component } from 'react';
import axios from 'axios';
import {Helmet} from "react-helmet";

export default class PlaceDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
        loading:true,
        place:null
    }
  }
  componentDidMount() {
    axios.get('https://api2.douglovesyou.com/public/places/' + this.props.match.params.id)
        .then(res=>{
            this.setState({
                place:res.data[0],
                loading:false
            })
        })
        .catch(error=>{
            console.log('error!',error)
        })
  }
  render() {
    var {place} = this.state;
    console.log(place?.name)
    console.log('place detail props',this.props)
    return this.state.loading ? (
        <div>loading</div>
        ) : (
            <div>
                <Helmet>
                <title>{place.name}</title>
                <meta property="og:type" content="website" />
                <meta property="og:title" content={place.name} />
                <meta property="og:description" content={place.description} />
                </Helmet>
      <div>{place.name}</div>
            </div>
    )
  }
}
