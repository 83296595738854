import React from 'react';
import './del.css';

const del = require('../assets/delete.png');

export default class DeleteMyAccount extends React.Component {
    render() {
        return (
            <div className="delcontainer">
                <div className="delinner">
                    <h2>Deleting your Doug account:</h2>
                    <p>To delete your account, tap the bottom right icon to go to the Profile screen of the Doug app.</p>
                    <p>Scroll to the bottom of the screen and tap "Delete My Account". After a confirmation, your account and all associated data will be deleted.</p>
                    <br/>
                    <p>Please note that any account and associated data that is deleted if gone forever!</p>
                    <img src={del} />
                </div>
            </div>
        )
    }
}