import React, { Component } from 'react';
import { Navbar, Nav, Container, Row, Col, Button } from 'react-bootstrap';
import { Player, ControlBar} from 'video-react';
import Lottie from 'react-lottie';
import VibeSteps from './VibeSteps';
import manza from '../assets/manza.jpg';
import dia from '../assets/dia.jpg';
import world_cup from '../assets/world_cup.jpg';
import doug_cards from '../assets/doug_cards_three_column.jpg';
import doug_cards_mobile from '../assets/doug_cards_mobile_2.jpg';

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  componentDidMount() {

  }
  render() {

    return (
      <>
      <Container style={{marginTop:25}}>
        <Row className="marginrowbottom flex-row">
          <Col md={6} className="mobileLottie d-flex align-items-center justify-content-center" style={{flexDirection:'column'}}>
            <div style={{
              width:'100%',
              maxWidth:800,
              paddingBottom:25
              }}>
            <Lottie options={{
              loop:true,
              autoplay:true,
              animationData:require('../assets/lottie/intro_animation_ver_2.json'),
              rendererSettings: {
              }
            }}
            width='90%'
            />
            </div>
            <Button variant="info" style={{
              color:'white',
              fontFamily:'Rubik Mono One',
              marginLeft:14,
              fontSize:30
            }}
            href="#getdoug"
            >Get Doug</Button>
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-center" style={{flexDirection:'column'}}>
          <img src={doug_cards} style={{width:'100%',borderRadius:5}} className="landingPageDesktop"/>
          <img src={doug_cards_mobile} style={{width:'100%',borderRadius:5}} className="landingPageMobile"/>
          </Col>

        </Row>
      </Container>
      <Container className="desktoppaddingtop">
        <Row className="marginrowbottom flex-row">
          <Col md={{span:6,order:1}} xs={{order:2}}  className="d-flex align-items-center justify-content-center">
          <img style={{width:300}} src={require('../assets/find_great_places.jpg')}/>
          </Col>
          <Col md={{span:6,order:2}} className="d-flex align-items-center justify-content-center mobileextrabottom" style={{flexDirection:'column'}}>
            <h1 style={{marginBottom:15}} xs={{order:1}} className="sub1 vibesectionmobile">Find Great Places</h1>
            <p className="maincolumntext">From Restaurants and Retail to Museums and Parks, Doug makes it easy to find only the best Places to go.</p>
          </Col>

        </Row>
      </Container>
      <Container className="desktoppaddingtop desktoppaddingbottom">
        <Row className="marginrowbottom flex-row">
          <Col md={6}className="d-flex align-items-center justify-content-center mobileextrabottom" style={{flexDirection:'column'}}>
            <h1 style={{marginBottom:15}} className="sub1 vibesectionmobile">Share the Good Vibes</h1>
            <p className="maincolumntext">Tell everyone why and what you love about a Place.</p>
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-center">
          <img style={{width:300}} src={require('../assets/vibe_group.jpg')}/>
          </Col>

        </Row>
      </Container>
      <Container>
      <Row className="rowmargintop">
      <Col className="d-flex align-items-center justify-content-center" style={{flexDirection:'column',marginBottom:45}}>
      <h1 style={{marginBottom:45}} className="sub1 vibesectionmobile">A Vibe is how you'd describe a Place to your friend.</h1>

        <Row>
          <Col>
          <div className="lottieWrap">
          <Lottie options={{
            loop:true,
            autoplay:true,
            animationData:require('../assets/lottie/flower.json'),
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          height={150}
          width={150}
          />
          <div className="lottieName">Beautiful</div>
          <div className="lottieDesc">This place is beautiful.</div>
          </div>
          </Col>
          <Col>
          <div className="lottieWrap">
          <Lottie options={{
            loop:true,
            autoplay:true,
            animationData:require('../assets/lottie/pizza.json'),
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          height={150}
          width={150}
          />
          <div className="lottieName">Pizza</div>
          <div className="lottieDesc">Find perfect pizza here.</div>
          </div>
          </Col>
          <Col>
          <div className="lottieWrap">
          <Lottie options={{
            loop:true,
            autoplay:true,
            animationData:require('../assets/lottie/coffee.json'),
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          height={150}
          width={150}
          />
          <div className="lottieName">Coffee Spot</div>
          <div className="lottieDesc">vibing on this coffee.</div>
          </div>
          </Col>
          <Col>
          <div className="lottieWrap">
          <Lottie options={{
            loop:true,
            autoplay:true,
            animationData:require('../assets/lottie/local.json'),
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          height={150}
          width={150}
          />
          <div className="lottieName">Local</div>
          <div className="lottieDesc">Locals hang out here.</div>
          </div>
          </Col>

        </Row>
        <Row>
          <Col>
          <VibeSteps/>
          </Col>
        </Row>



      </Col>

      </Row>
    </Container>
      <Container fluid
      style={{
        borderBottom:'1px solid lightgrey',
        borderTop:'1px solid lightgrey',
        paddingTop:50
      }}
      className="color"
      >
        <Container>
        <Row className="padrow color" id="getdoug">
          <Col md={4} className="mb-3 d-flex align-items-center justify-content-center" style={{flexDirection:'column'}}>
            <h1 className="sub1">Get Doug</h1>
          </Col>
          <Col md={4} className="mb-3 d-flex align-items-center justify-content-center">
            <a href="https://apps.apple.com/us/app/doug-loves-you/id1541973620" target="_blank">
            <img src={require('../assets/ios_download.jpg')} style={{width:175}}/>
            </a>
          </Col>
          <Col md={4} className="mb-3 d-flex align-items-center justify-content-center">
            <a href="https://play.google.com/store/apps/details?id=com.doug.douglovesyou" target="_blank">
            <img src={require('../assets/google_download.jpg')} style={{width:175}}/>
            </a>
          </Col>
        </Row>
        </Container>
        </Container>
        <Container className="desktoppaddingtop desktoppaddingbottom">
          <Row className="marginrowbottom flex-row">
            <Col md={6} className="d-flex align-items-center justify-content-center">
            <Lottie options={{
              loop:true,
              autoplay:true,
              animationData:require('../assets/lottie/store.json'),
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
            height={250}
            width={250}
            />
            </Col>
            <Col md={6}className="d-flex align-items-center justify-content-center" style={{flexDirection:'column'}}>
              <h1 style={{marginBottom:15}} className="sub1">Doug for Business</h1>
              <p className="maincolumntext">We're here to help.</p>
              <Button variant="info" style={{
                color:'white',
                fontFamily:'Rubik Mono One',
                marginLeft:14,
                fontSize:30
              }}
              href="/doug-for-business"
              >Learn More</Button>

            </Col>

          </Row>
        </Container>


      </>
    )
  }
}
