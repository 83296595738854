import React, { Component } from 'react';

export default class Other extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  render() {
    return (
      <div>Other</div>
    )
  }
}
