import React from "react"
import FooterEmailSignup from './footerEmail';

const Footer = () => <footer className="page-footer font-small dougFooter">
    <div className="container-fluid text-center text-md-left">
        <div className="row">

            <div className="col-md-4 mb-md-0 mb-4">
                <h5 className="" style={{letterSpacing:-.5}}>Doug - A really helpful app.</h5>
                <ul className="list-unstyled">
                    <li>contact doug:</li>
                    <li><a href="mailto:info@douglovesyou.com">info@douglovesyou.com</a></li>
                    <li><br/></li>
                    <li><a href="/privacy">privacy</a></li>
                </ul>
            </div>

            <div className="col-md-4 mb-md-0 mb-4">
                <h5 className="" style={{letterSpacing:-.5}}>Doug Info</h5>
                <ul className="list-unstyled">
                    <li><a href="/howtodoug">How to Doug</a></li>
                    <li><a href="/community-guidelines">Community Guidelines</a></li>
                    <li><a href="/help-faq">Help & FAQ</a></li>
                    <li><a href="/doug-for-business">Doug for Business</a></li>
                </ul>
            </div>

            <div className="col-md-4 mb-md-0 mb-4">
                <h5 className="" style={{letterSpacing:-.5}}>Get Doug</h5>
                <ul className="list-unstyled">
                    <li><a href="https://apps.apple.com/us/app/doug-loves-you/id1541973620" target="_blank">download iOS</a></li>
                    <li><a href="https://play.google.com/store/apps/details?id=com.doug.douglovesyou" target="_blank">download Android</a></li>
                </ul>
                <FooterEmailSignup/>
            </div>
        </div>
    </div>

    <div className="footer-copyright text-center py-3">
    <img src={require('../assets/doug_heart.png')} style={{width:75}}/><br/>
    © 2022 Copyright: J&G Media
    </div>

</footer>

export default Footer
