import React, { Component } from 'react';
import { Navbar, Nav, Container, Row, Col, Button } from 'react-bootstrap';

export default class GetDoug extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return (
      <Container style={{height:'50vh',flexDirection:'column'}} className="d-flex align-items-center justify-content-center">
          <h1>Get Doug</h1>
          <div className="getBox">
          <a href="https://apps.apple.com/us/app/doug-loves-you/id1541973620" target="_blank">
          <img src={require('../assets/ios_download.jpg')} style={{width:175}}/>
          </a>
          </div>
          <div className="getBox">
          <a href="https://play.google.com/store/apps/details?id=com.doug.douglovesyou" target="_blank">
          <img src={require('../assets/google_download.jpg')} style={{width:175}}/>
          </a>
          </div>
      </Container>
    )
  }
}
