import React, { Component } from 'react';
import manza from '../assets/manza.jpg';

export default class HowToDoug extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  
componentDidMount() {
  window.scrollTo(0, 0)
}
  render() {
    return (
      <div style={styles.container} className="mainContainer">
        <h1 className="topTitle">How to Doug</h1>
          <div className="contentBody">
            <div className="contentSectionWrap extraBottomMargin">
              <h2 className="sub1" style={{marginTop:25}}>Definitions</h2>
              <p className="bodyp">
              <b>Place</b> - a local business. Your favorite place to get noodles. A park. A gathering spot. A basketball court. A clothing boutique, bar, or restaurant. A public institution like a library. Or even a local hiking trail.
              </p>
              <p className="bodyp">
              <b>A Place is not</b> - well... everything is really a place, but the Places we’re interested in are local businesses, places that really rely on the value of the communities advocating for them and supporting them, and places that add value to the collective community supporting them.
              </p>
              <p className="bodyp">
              <b>Vibe (Good Vibes)</b> - It’s like a badge. a positive indicator of your experience at a Place. A Vibe can represent how a Place makes you feel. Is it relaxing? Is it beautiful inside? Or a cozy old dive. Do they have good music? Or from a practical standpoint - do they have public wifi? Can you bring your pet? Do kids have fun here? Vibes are best accompanied by a photo, and a quick comment or emoji that adds more depth to your description. Vibes can range from practical to whimsical and esoteric - some Vibes are funny.
              </p>
              <p className="bodyp">
              <b>A Vibe is not</b> - <i>A Vibe is not a review.</i>
              </p>
            </div>
            <div className="contentImageWrap">
            <img src={manza}/>
            <div className="imageCaption">
            a place in Doug with lots of Vibes.
            </div>
            </div>
            <div className="contentSectionWrap">
              <h2 className="sub1">1. Vibing a place</h2>
              <p className="bodyp">In the Places tab, Doug always shows you what is nearby (you can also view Doug activity in other locations... more on that later). Find a local business you want to show some love to.
The down arrow opens a menu. Click the flame icon to Vibe. Pick a Vibe that matches your experience and click Next.  Add a picture (optional, but please do), and a comment or emoji, and click Submit. You’re done!
</p>
              <div className="comingSoon">
                <i>Coming soon</i> - <b>How to Vibe video.</b>
              </div>
            </div>
            <div className="contentSectionWrap">
              <h2 className="sub1">2. Add a Place to Doug!</h2>
              <p className="bodyp">Don’t see a Place you think should be on Doug? Add it. Tap the + circle icon in the upper left corner of the screen. Follow a series of menus to add a Place to Doug. Your submission will be added to a queue and approved by Doug as fast as we can spin our tiny little hamster wheels (usually within 4 hours). Once approved you will be notified and now you and everyone else can Vibe this place on Doug. You will also get a secret note on your account that thanks you for adding this place to Doug.</p>
              <div className="comingSoon">
                <i>Coming soon</i> - <b>Add a Place video.</b>
              </div>

            </div>
            <div className="contentSectionWrap">
              <h2 className="sub1">3. Explore Doug</h2>
              <p className="bodyp">Head over to the <b>Activity</b> tab. This shows what other users are posting and sharing in Doug. Almost everything in Doug is clickable. Interested in that user? Click their profile image to see all of the Places they have Vibed. Want to see all the snaps in your area (i.e., a simple Vibe that means “I was there and I like it!”), or at a particular Place? Click on the Vibe icon itself. Click on a Place name to go to the Place to get more specific info. Really, just click everywhere. You’ll figure it out.</p>
              <div className="comingSoon">
                <i>Coming soon</i> - <b>Activity tab demo video.</b>
              </div>

            </div>
            <div className="contentSectionWrap">
              <h2 className="sub1">4. Explore more Doug</h2>
              <p className="bodyp">What about Doug in other cities? Don’t worry - we’ve got you covered. Tap the Doug logo at the top of the screen and type the name of the city you want to explore. We call this teleporting and it will allow you to use Doug as if you were in that very city - Useful for exploring, trip planning, and meeting other Doug users. When you’re done, tap the logo again and click ‘clear teleport’ to return to your current location. You can also adjust your search radius on this page.</p>
              <div className="comingSoon">
                <i>Coming soon</i> - <b>Teleport! video.</b>
              </div>

            </div>
            <div className="contentSectionWrap">
              <h2 className="sub1">5. Interact with other users</h2>
              <p className="bodyp">Doug is social. You can message people, and share Places and Vibes with them. Follow people who Vibe dope shit. See a cool Vibe? Send it to your friend and they can explore the Vibe and the Place. Tap the airplane icon in the top right corner of the screen to get started.
              </p>
              <p className="bodyp">Comment on Vibes! If you see a Vibe you relate to, comment on it. Make sure your comment is appropriate, adds to the topic of the Vibe, and helps build community around the business.
</p>
              <div className="comingSoon">
                <i>Coming soon</i> - <b>Doug sharing video.</b>
              </div>

            </div>
            <div className="contentSectionWrap">
              <h2 className="sub1">6. But wait, there’s more!</h2>
              <p className="bodyp">Spend some time on your profile page. Places you save will appear here, as well as a history of your actions on Doug. </p>
            </div>

          </div>
      </div>
    )
  }
}



const styles= {
  imageWrap : {
    flex:1,
    display:'flex',
    maxWidth:1280,
    margin:'auto',
    paddingTop:'55%',
    borderRadius:5
  },
  image : {
    width:'100%',
    borderRadius:5
  },
  section : {
  },
  container : {
    maxWidth:1000,
    margin:'auto',
    paddingLeft:15,
    paddingRight:15,
    paddingBottom:135,
    justifyContent:'flex-start',
    alignItems:'flex-start'
  },
  sectionHeader : {
    fontFamily: 'Source Serif Pro',
    fontSize:140,
    fontWeight:'bold',
    letterSpacing:-5,
    color:'#404040',
    lineHeight:.8
  },
}
