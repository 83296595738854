import React, { Component } from 'react';
import { Navbar, Nav, Container, Row, Col, Button, Input } from 'react-bootstrap';
import axios from 'axios';
import Lottie from 'react-lottie';
//require('dotenv').config()

export default class EmailSignup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open:false,
      email:'',
      submitting:false,
      success:false
    }
  }
  componentDidMount() {
    if(!localStorage.getItem('dougstoppopup')) {
      setTimeout(
        () => {
          this.setState({open:true})

        }
        ,250 /// milliseconds = 10 seconds
      );

    }
  }
  submitEmail = () => {
    this.setState({submitting:true})
    axios({
        method:'POST',
        url: 'https://' + process.env.REACT_APP_EMAILFORMAPI + '/emails/addtolist/',
        data: {
          email:this.state.email
        }
    }).then(result=>{
      this.setState({
        submitting:false,
        success:true
      })
      localStorage.setItem('dougstoppopup', true)
    }).catch(error=>{
      this.setState({submitting:false,error:true})
    })

  }
  render() {
    return (
      <div className={`DougModal ${this.state.open ? 'Show' : ''}`}>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>

      {this.state.error ? (
        <div style={{
          display:'flex',
          flexDirection:'column'
        }}>
        <h2 style={{
          color:'white',
          marginBottom:0,
          fontSize:30,
          fontWeight:'bold',
          letterSpacing:-1,
          fontFamily:'Rubik Mono One'
        }}
        className="DougModalMobileH2"
        >Hmmm...</h2>
        <p style={{color:'white', marginTop:10,
        letterSpacing:0,
        fontSize:18,
        width:'90%'
      }}>There was a problem submitting your email.</p>
      <Button variant="modal" style={{
        color:'#3c3c3c',
        fontFamily:'Rubik Mono One',
        marginTop:5
      }}
      onClick={()=>{
        this.setState({
          error:false,
          submitting:false,
          email:''

        })
      }}
      >Try again</Button>
        <Button variant="modal" style={{
          color:'#8c8c8c',
          fontFamily:'Rubik Mono One',
          marginTop:5
        }}
        onClick={()=>{
          this.setState({
            open:false
          })
        }}
        >Close</Button>
        </div>
      ) : this.state.success ? (
        <div style={{
          display:'flex',
          flexDirection:'column',
          alignItems:'center'
        }}>
        <h2 style={{
          color:'white',
          marginBottom:0,
          fontSize:30,
          fontWeight:'bold',
          letterSpacing:-1,
          fontFamily:'Rubik Mono One'
        }}
        className="DougModalMobileH2"
        >Thanks!</h2>
        <p style={{color:'white', marginTop:10,
        letterSpacing:0,
        fontSize:18,
        width:'90%',
        textAlign:'center'
      }}>We'll be in touch.</p>

        <Button variant="modal" style={{
          color:'#3c3c3c',
          fontFamily:'Rubik Mono One',
          marginTop:5
        }}
        onClick={()=>{
          this.setState({
            open:false
          })
        }}
        >Close</Button>
        </div>
      ) : (
        <>
        <h2 style={{
          color:'white',
          marginBottom:0,
          fontSize:30,
          fontWeight:'bold',
          letterSpacing:-1,
          fontFamily:'Rubik Mono One'
        }}
        className="DougModalMobileH2"
        >Get Doug Updates!</h2>
        <p style={{color:'white', marginTop:10,
        letterSpacing:0,
        fontSize:18,
        width:'90%'
      }}>Doug is rolling out from Boulder to Burque! To find out when Doug is offically active in your location plus other exciting Doug news, please leave your email here.</p>
        <input style={{
          width:'90%',
          height:40,
          borderRadius:5,
          fontSize:20,
          paddingLeft:5
          }}
          type="email"
        placeholder="email"
        value={this.state.email}
        onChange={(e)=>{
          this.setState({
            email:e.target.value
          })
        }}
        />
        <div style={{
          width:'90%',
          display:'flex',
          flexDirection:'column'
        }}>
        <Button variant="modal" style={{
          color:'#3c3c3c',
          fontFamily:'Rubik Mono One',
          marginTop:10
        }}
        onClick={()=>{
          this.submitEmail()
        }}
        >Sign Up</Button>
        <Button variant="modal" style={{
          color:'#8c8c8c',
          fontFamily:'Rubik Mono One',
          marginTop:5
        }}
        onClick={()=>{
          this.setState({
            open:false
          })
          localStorage.setItem('dougstoppopup', true)
        }}
        >No Thanks</Button>
        </div>

        </>
      )}

      </div>
      {this.state.submitting &&
        <div style={{

        }}
        className="DougModalOverlay"
        >
        <Lottie options={{
          loop:true,
          autoplay:true,
          animationData:require('../assets/lottie/loading-state.json'),
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        height={100}
        width={100}
        />
        </div>

      }
      </div>
    )
  }
}
