import React, { Component } from 'react';

export default class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
componentDidMount() {
  window.scrollTo(0, 0)
}
  render() {
    return (
      <div style={styles.container} className="mainContainer">
      <h1 style={{'text-align':'left'}}>Terms of Use</h1>
      <h3 style={{'text-align':'left'}}>These terms will be effective March 10, 2021.</h3>
      <p style={{'text-align':'left'}}>Welcome to Doug!</p>
      <p style={{'text-align':'left'}}>These Terms of Use (or &quot;Terms&quot;) govern your use of Doug, except where we expressly state that
separate terms (and not these) apply, and provide information about the Doug Service (the
&quot;Service&quot;), outlined below. When you create a Doug account or use Doug, you agree to these
terms.</p>
  <p style={{'text-align':'left'}}>The Doug Service is provided to you by J&amp;G Media, LLC. These Terms of Use therefore
constitute an agreement between you and J&amp;G Media, LLC.</p>
      <h3 style={{'text-align':'left'}}>ARBITRATION NOTICE: YOU AGREE THAT DISPUTES BETWEEN YOU AND US
WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU
WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR
CLASS-WIDE ARBITRATION. WE EXPLAIN SOME EXCEPTIONS AND HOW YOU
CAN OPT OUT OF ARBITRATION BELOW.</h3>
    <h2 style={{'text-align':'left'}}>The Doug Service</h2>
    <p style={{'text-align':'left'}}>We agree to provide you with the Doug Service. The Service includes all of the Doug products,
features, applications, services, technologies, and software that we provide to advance Doug&#39;s
mission: To help you support, save, and discover small businesses you love. The Service is made
up of the following aspects:</p>
  <ul>
    <li style={{'text-align':'left'}}><b>Offering opportunities to connect communities with their favorite businesses. Giving
users the ability to ascribe positive attributes to local businesses you love, generate
media about those businesses, communicate directly with them, share the love with
other users (privately and publicly), and discover new businesses to share. </b><br/>
Small businesses that capture local culture are the backbone of economies worldwide.
We want to strengthen your relationships with the businesses you love and discover
through shared experiences. So we build systems that enable you to support these
businesses through assigning positive, descriptive, often playful Vibes and media
(images, video, etc.), which spark comment threads (including media), which naturally
and seamlessly help other users find businesses they’re looking for. We use this
information to help you create, find, join, and share in experiences around businesses that
matter to you. Part of that is highlighting businesses, features, offers, and accounts you
might be interested in (e.g., if you live in Detroit you may want to follow a user who lives

and frequents local businesses in Denver and vice versa), and offering ways for you to
experience Doug, based on businesses you and others visit on and off Doug.</li>
<li style={{'text-align':'left'}}><b>Fostering a positive, inclusive, and safe environment to support local businesses.</b><br/>
We develop and use tools and offer resources to our community members that help to
make their experiences positive, inclusive and expansive, including when we think they
might need help. We also have teams and systems that work to combat abuse and
violations of our Terms and policies, as well as harmful and deceptive behavior. We use
all the information we have- including your information- to try to keep our platform
secure. We also may share information about misuse or harmful content with law
enforcement.</li>
<li style={{'text-align':'left'}}><b>Developing and using technologies that help us consistently serve our growing community.</b><br/> Organizing and analyzing information for our growing community is central to our Service. A big part of our Service is creating and using cutting-edge technologies that help us personalize, protect, and improve our Service on a large scale for a broad community. Technologies like artificial intelligence and machine learning give us the power to apply complex processes across our Service. Automated technologies also help us ensure the functionality and integrity of our Service.</li>
<li style={{'text-align':'left'}}><b>Ensuring access to our Service.</b><br/>To operate our Service, we must store and transfer data across our systems, including, potentially, outside of your country of residence. The use of this global infrastructure is necessary and essential to provide our Service.</li>
<li style={{'text-align':'left'}}><b>Connecting you with brands, products, and services in ways you care about.</b><br/>We use data from Doug, as well as from third-party partners, including businesses, to show you offers, and other sponsored content that we believe will be meaningful to you. And we try to make that content as relevant as all your other experiences on Doug.</li>
<li style={{'text-align':'left'}}><b>Research and innovation.</b><br/>We use the information we have to study our Service and collaborate with others on research to make our Service better and contribute to the well-being of our community</li>
  </ul>
  <h2 style={{'text-align':'left'}}>How Our Service is Funded</h2>
<p style={{'text-align':'left'}}>Instead of paying to use Doug, by using the Service covered by these Terms, you acknowledge that we can show you rewards and other promotions that businesses and organizations may pay us to promote on and off Doug. We use your personal data, such as information about your activity and interests, to show you rewards that are more relevant to you.</p>
<p style={{'text-align':'left'}}>We show you relevant and useful rewards without telling the businesses who you are although they can directly present rewards to you as well. We don’t sell your personal data. We allow businesses to tell us things like their business goal and the kind of customers they want to make offers to. We then show their reward or other promotion to people who might be interested.</p>
<p style={{'text-align':'left'}}>We also provide businesses on Doug with reports about the community activity generated for them and the impact of their rewards and other promotions to help them understand how people are interacting with their content on and off Doug. For example, we provide general demographic, interest and interaction information to businesses to help them better understand their audience and how Doug helps them (1) generate contact and transactions from existing customers and (2) acquire new customers. We don’t share information that directly identifies you (information such as your name or email address that by itself can be used to contact you or identifies who you are) unless you give us specific permission. Learn more about how Doug rewards work here.</p>
<p style={{'text-align':'left'}}>You may see branded content on Doug posted by account holders who promote products or services based on a commercial relationship with the business partner mentioned in their content. You can learn more about this here.</p>
<h2 style={{'text-align':'left'}}>The Data Policy</h2>
<p style={{'text-align':'left'}}>Providing our Service requires collecting and using your information. The Data Policy explains how we collect, use, and share information. It also explains the many ways you can control your information, including in the Doug Privacy and Security Settings. You must agree to the Data Policy to use Doug.</p>
<h2 style={{'text-align':'left'}}>Your Commitments</h2>
<p style={{'text-align':'left'}}>In return for our commitment to provide the Service, we require you to make the below commitments to us.</p>
<p style={{'text-align':'left'}}><b>Who Can Use Doug.</b> We want our Service to be as open and inclusive as possible, but we also want it to be safe, secure, and in accordance with the law. So, we need you to commit to a few restrictions in order to be part of the Doug community.</p>
<ul>
  <li style={{'text-align':'left'}}>You must be at least 13 years old.</li>
  <li style={{'text-align':'left'}}>You must not be prohibited from receiving any aspect of our Service under applicable laws or engaging in payments related Services if you are on an applicable denied party listing.</li>
  <li style={{'text-align':'left'}}>We must not have previously disabled your account for violation of law or any of our policies.</li>
  <li style={{'text-align':'left'}}>You must not be a convicted sex offender.</li>
</ul>
<p style={{'text-align':'left'}}><b>How You Can't Use Doug.</b> Providing a safe and open Service for a broad community requires that we all do our part.</p>
<ul>
  <li style={{'text-align':'left'}}><b>You can't post objectionable content such as nudity, overt sexuality, hate symbols or hate speech.</b>
  <br/>Doug is a safe space. You are restrained from posting images or text that demonstrate or imply any of the following:
  <ul style={{marginTop:10}}>
  <li style={{marginBottom:5}}>nudity</li>
  <li style={{marginBottom:5}}>overt sexuality</li>
  <li style={{marginBottom:5}}>gore or graphic violence</li>
  <li style={{marginBottom:5}}>hate symbols</li>
  <li style={{marginBottom:5}}>hate speech</li>
  </ul>
  <br/>If you post objectionable content to Doug, your account will be disbaled and you may be banned from further use of the system.
  <br/>For more information please see the "Content Removal and Disabling or Terminating Your Account" section below.
  <br/><br/><b>To report inappropriate content on Doug, please use the "hamburger" button on the top right portion of each Vibe card.</b>
  </li>

  <li style={{'text-align':'left'}}><b>You can't impersonate others or provide inaccurate information.</b><br/>You don't have to disclose your identity on Doug, but you must provide us with accurate and up to date information (including registration information), which may include providing personal data. Also, you may not impersonate someone or something you aren't, and you can't create an account for someone else unless you have their express permission.</li>
  <li style={{'text-align':'left'}}><b>You can't do anything unlawful, misleading, or fraudulent or for an illegal or unauthorized purpose.</b></li>
  <li style={{'text-align':'left'}}><b>You can't violate (or help or encourage others to violate) these Terms or our policies, including any Doug Community Guidelines that are developed.</b><br/> If you post branded content, you must comply with our Branded Content Policies, which require you to use our branded content tool. Learn how to report conduct or content in our Help Center.</li>
  <li style={{'text-align':'left'}}><b>You can't do anything to interfere with or impair the intended operation of the Service.</b><br/>This includes misusing any reporting, dispute, or appeals channel, such as by making fraudulent or groundless reports or appeals.</li>
  <li style={{'text-align':'left'}}><b>You can't attempt to create accounts or access or collect information in unauthorized ways.</b><br/>This includes creating accounts or collecting information in an automated way without our express permission.</li>
  <li style={{'text-align':'left'}}><b>You can’t sell, license, or purchase any account or data obtained from us or our Service.</b><br/></li>
  <li style={{'text-align':'left'}}><b>You can't post someone else’s private or confidential information without permission or do anything that violates someone else's rights, including intellectual property rights (e.g., copyright infringement, trademark infringement, counterfeit, or pirated goods).</b><br/>You may use someone else's works under exceptions or limitations to copyright and related rights under applicable law. You represent you own or have obtained all necessary rights to the content you post or share. Learn more, including how to report content that you think infringes your intellectual property rights, here.</li>
  <li style={{'text-align':'left'}}><b>You can’t modify, translate, create derivative works of, or reverse engineer our products or their components.</b></li>
  <li style={{'text-align':'left'}}><b>You can't use a domain name or URL in your username without our prior written consent.</b></li>
</ul>
<p style={{'text-align':'left'}}><b>Permissions You Give to Us.</b> As part of our agreement, you also give us permissions that we need to provide the Service.</p>
<ul>
  <li style={{'text-align':'left'}}><b>We do not claim ownership of your content, but you grant us a license to use it.</b><br/>Nothing is changing about your rights in your content. We do not claim ownership of your content that you post on or through the Service and you are free to share your content with anyone else, wherever you want. However, we need certain legal permissions from you (known as a “license”) to provide the Service. When you share, post, or upload content that is covered by intellectual property rights (like photos or videos) on or in connection with our Service, you hereby grant to us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to host, use, distribute, modify, run, copy, publicly perform or display, translate, and create derivative works of your content (consistent with your privacy and application settings). This license will end when your content is deleted from our systems. You can delete content individually or all at once by deleting your account. To learn more about how we use information, and how to control or delete your content, review the Data Policy.</li>
  <li style={{'text-align':'left'}}><b>Permission to use your username, profile picture, and information about your relationships and actions with accounts, rewards, and sponsored content.</b><br/>You give us permission to show your username, profile picture, and information about your actions (such as Vibes and Loves) or relationships (such as follows) next to or in connection with accounts, offers, and other sponsored content that you follow or engage with that are displayed on Doug, without any compensation to you. For example, we may show that you Vibed a listed business that has paid us to display its offers on Doug. As with actions on other content and follows of other accounts, actions on business’ content and follows of listed business’ accounts can be seen only by people who have permission to see that content or follow. We will also respect your offer/reward settings. You can learn more here about your ad settings.</li>
  <li style={{'text-align':'left'}}><b>You agree that we can download and install updates to the Service on your device.</b></li>
</ul>
<h2 style={{'text-align':'left'}}>Additional Rights We Retain</h2>
<ul>
<li style={{'text-align':'left'}}>If you select a username or similar identifier for your account, we may change it if we believe it is appropriate or necessary (for example, if it infringes someone's intellectual property or impersonates another user).</li>
<li style={{'text-align':'left'}}>If you use content covered by intellectual property rights that we have and make available in our Service (for example, Vibes, images, designs, videos, or sounds we provide that you add to content you create or share), we retain all rights to our content (but not yours).</li>
<li style={{'text-align':'left'}}>You can only use our intellectual property and trademarks or similar marks as expressly permitted by our Brand Guidelines or with our prior written permission.</li>
<li style={{'text-align':'left'}}>You must obtain written permission from us or under an open source license to modify, create derivative works of, decompile, or otherwise attempt to extract source code from us.</li>
</ul>
<h2 id="accountdeletion" style={{'text-align':'left'}}>Content Removal and Disabling or Terminating Your Account</h2>
<ul>
<li style={{'text-align':'left'}}>To request deletion of your account and all data, please email us at <b>info@douglovesyou.com</b>. Please include the word "delete" in the subject line. Read below for more information.</li>
<li style={{'text-align':'left'}}>We can remove any content or information you share on the Service if we believe that it violates these Terms of Use, our policies, or we are permitted or required to do so by law. We can refuse to provide or stop providing all or part of the Service to you (including terminating or disabling your access to the Service) immediately to protect our community or services, or if you create risk or legal exposure for us, violate these Terms of Use or our policies, if you repeatedly infringe other people's intellectual property rights, or where we are permitted or required to do so by law. We can also terminate or change the Service, remove or block content or information shared on our Service, or stop providing all or part of the Service if we determine that doing so is reasonably necessary to avoid or mitigate adverse legal or regulatory impacts on us. If you believe your account has been terminated in error, or you want to disable or permanently delete your account, email us at <b>info@douglovesyou.com</b> Please include the word "delete" in the subject line. When you request to delete content or your account, the deletion process will automatically begin no more than 30 days after your request. It may take up to 90 days to delete content after the deletion process begins. While the deletion process for such content is being undertaken, the content is no longer visible to other users, but remains subject to these Terms of Use and our Data Policy. After the content is deleted, it may take us up to another 90 days to remove it from backups and disaster recovery systems.</li>
<li style={{'text-align':'left'}}>Content will not be deleted within 90 days of the account deletion or content deletion process beginning in the following situations:</li>
<ul>
<li style={{'text-align':'left'}}>where your content has been used by others in accordance with this license and they have not deleted it (in which case this license will continue to apply until that content is deleted); or</li>
<li style={{'text-align':'left'}}>where deletion within 90 days is not possible due to technical limitations of our systems, in which case, we will complete the deletion as soon as technically feasible; or</li>
<li style={{'text-align':'left'}}>where deletion would restrict our ability to:</li>
<ul>
<li style={{'text-align':'left'}}>investigate or identify illegal activity or violations of our terms and policies (for example, to identify or investigate misuse of our products or systems);</li>
<li style={{'text-align':'left'}}>protect the safety and security of our products, systems, and users;</li>
<li style={{'text-align':'left'}}>comply with a legal obligation, such as the preservation of evidence; or</li>
<li style={{'text-align':'left'}}>comply with a request of a judicial or administrative authority, law enforcement, or a government agency;</li>
</ul>
<li style={{'text-align':'left'}}>in which case, the content will be retained for no longer than is necessary for the purposes for which it has been retained (the exact duration will vary on a case-by-case basis).</li>

</ul>
<li style={{'text-align':'left'}}>If you delete or we disable your account, these Terms shall terminate as an agreement between you and us, but this section and the section below called "Our Agreement and What Happens if We Disagree" will still apply even after your account is terminated, disabled, or deleted.</li>
</ul>
<h2 style={{'text-align':'left'}}>Our Agreement and What Happens if We Disagree</h2>
<p style={{'text-align':'left'}}><b>Our Agreement</b></p>
<ul>
<li style={{'text-align':'left'}}>Your use of music on the Service is also subject to our Music Guidelines, and your use of our API is subject to our Platform Terms and Developer Policies. If you use certain other features or related services, you will be provided with an opportunity to agree to additional terms that will also become a part of our agreement. For example, if you use payment features, you will be asked to agree to the Community Payment Terms. If any of those terms conflict with this agreement, those other terms will govern.</li>
<li style={{'text-align':'left'}}>If any aspect of this agreement is unenforceable, the rest will remain in effect.</li>
<li style={{'text-align':'left'}}>Any amendment or waiver to our agreement must be in writing and signed by us. If we fail to enforce any aspect of this agreement, it will not be a waiver.</li>
<li style={{'text-align':'left'}}>We reserve all rights not expressly granted to you.</li>
</ul>
<p style={{'text-align':'left'}}><b>Who Has Rights Under this Agreement</b></p>
<ul>
<li style={{'text-align':'left'}}>Our past, present, and future affiliates and agents, including J&G Media, LLC, can invoke our rights under this agreement in the event they become involved in a dispute. Otherwise, this agreement does not give rights to any third parties.</li>
<li style={{'text-align':'left'}}>You cannot transfer your rights or obligations under this agreement without our consent.</li>
<li style={{'text-align':'left'}}>Our rights and obligations can be assigned to others. For example, this could occur if our ownership changes (as in a merger, acquisition, or sale of assets) or by law.</li>
</ul>
<p style={{'text-align':'left'}}><b>Who Is Responsible if Something Happens.</b></p>
<ul>
<li style={{'text-align':'left'}}>Our Service is provided "as is," and we can't guarantee it will be safe and secure or will work perfectly all the time. TO THE EXTENT PERMITTED BY LAW, WE ALSO DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.</li>
<li style={{'text-align':'left'}}>We also don’t control what people and others do or say, and we aren’t responsible for their (or your) actions or conduct (whether online or offline) or content (including unlawful or objectionable content). We also aren’t responsible for services and features offered by other people or companies, even if you access them through our Service.</li>
<li style={{'text-align':'left'}}>Our responsibility for anything that happens on the Service (also called "liability") is limited as much as the law will allow. If there is an issue with our Service, we can't know what all the possible impacts might be. You agree that we won't be responsible ("liable") for any lost profits, revenues, information, or data, or consequential, special, indirect, exemplary, punitive, or incidental damages arising out of or related to these Terms, even if we know they are possible. This includes when we delete your content, information, or account. Our aggregate liability arising out of or relating to these Terms will not exceed the greater of $100 or the amount you have paid us in the past twelve months.</li>
<li style={{'text-align':'left'}}>You agree to defend (at our request), indemnify and hold us harmless from and against any claims, liabilities, damages, losses, and expenses, including without limitation, reasonable attorney's fees and costs, arising out of or in any way connected with these Terms or your use of the Service. You will cooperate as required by us in the defense of any claim. We reserve the right to assume the exclusive defense and control of any matter subject to indemnification by you, and you will not in any event settle any claim without our prior written consent.</li>
</ul>
<p style={{'text-align':'left'}}><b>How We Will Handle Disputes.</b></p>
<ul>
<li style={{'text-align':'left'}}>Except as provided below, <b>you and we agree that any cause of action, legal claim, or dispute between you and us arising out of or related to these Terms or Doug ("claim(s)") must be resolved by arbitration on an individual basis. Class actions and class arbitrations are not permitted;</b> you and we may bring a claim only on your own behalf and cannot seek relief that would affect other Doug users. If there is a final judicial determination that any particular claim (or a request for particular relief) cannot be arbitrated in accordance with this provision's limitations, then only that claim (or only that request for relief) may be brought in court. All other claims (or requests for relief) remain subject to this provision.
</li>
<li style={{'text-align':'left'}}>Instead of using arbitration, you or we can bring claims in your local "small claims" court, if the rules of that court will allow it. If you don't bring your claims in small claims court (or if you or we appeal a small claims court judgment to a court of general jurisdiction), then the claims must be resolved by binding, individual arbitration. The American Arbitration Association will administer all arbitrations under its Consumer Arbitration Rules. <b>You and we expressly waive a trial by jury.</b>
<p>The following claims don't have to be arbitrated and may be brought in court: disputes related to intellectual property (like copyrights and trademarks), violations of our Platform Policy, or efforts to interfere with the Service or engage with the Service in unauthorized ways (for example, automated ways). In addition, issues relating to the scope and enforceability of the arbitration provision are for a court to decide.</p>
<p>This arbitration provision is governed by the Federal Arbitration Act.</p>
<p>You can opt out of this provision within 30 days of the date that you agreed to these Terms. To opt out, you must send your name, residence address, username, email address or phone number you use for your Doug account, and a clear statement that you want to opt out of this arbitration agreement, and you must send them here: J&G Media, LLC ATTN: Gary M. Feuerman, P.O. Box 806, Taos, NM  87571.</p>
</li>
<li style={{'text-align':'left'}}>Before you commence arbitration of a claim, you must provide us with a written Notice of Dispute that includes your name, residence address, username, email address or phone number you use for your Doug account, a detailed description of the dispute, and the relief you seek. Any Notice of Dispute you send to us should be mailed to J&G Media, LLC ATTN: Gary M. Feuerman, P.O. Box 806, Taos, NM  87571. Before we commence arbitration, we will send you a Notice of Dispute to the email address you use with your Doug account, or other appropriate means. If we are unable to resolve a dispute within thirty (30) days after the Notice of Dispute is received, you or we may commence arbitration.</li>
<li style={{'text-align':'left'}}>We will pay all arbitration filing fees, administration and hearing costs, and arbitrator fees for any arbitration we bring or if your claims seek less than $75,000 and you timely provided us with a Notice of Dispute. For all other claims, the costs and fees of arbitration shall be allocated in accordance with the arbitration provider's rules, including rules regarding frivolous or improper claims.</li>
<li style={{'text-align':'left'}}>For any claim that is not arbitrated or resolved in small claims court, you agree that it will be resolved exclusively in the U.S. District Court for the District of New Mexico or a state court located in Taos County. You also agree to submit to the personal jurisdiction of either of these courts for the purpose of litigating any such claim.</li>
<li style={{'text-align':'left'}}>The laws of the State of New Mexico, to the extent not preempted by or inconsistent with federal law, will govern these Terms and any claim, without regard to conflict of law provisions.</li>
</ul>
<p style={{'text-align':'left'}}><b>Unsolicited Material.</b></p>
<p style={{'text-align':'left'}}>We always appreciate feedback or other suggestions, but may use them without any restrictions or obligation to compensate you for them, and are under no obligation to keep them confidential.</p>
<h2 style={{'text-align':'left'}}>Updating These Terms</h2>

<p style={{'text-align':'left'}}>We may change our Service and policies, and we may need to make changes to these Terms so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to these Terms and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Terms. If you do not want to agree to these or any updated Terms, you can delete your account, here.</p>
<p style={{'text-align':'left'}}>Revised: February 6, 2022</p>
      </div>
    )
  }
}


const styles= {
  imageWrap : {
    flex:1,
    display:'flex',
    maxWidth:1280,
    margin:'auto',
    paddingTop:'55%',
    borderRadius:5
  },
  image : {
    width:'100%',
    borderRadius:5
  },
  section : {
  },
  container : {
    maxWidth:1000,
    margin:'auto',
    paddingLeft:15,
    paddingRight:15,
    paddingBottom:135,
    justifyContent:'flex-start',
    alignItems:'flex-start'
  },
  sectionHeader : {
    fontFamily: 'Source Serif Pro',
    fontSize:140,
    fontWeight:'bold',
    letterSpacing:-5,
    color:'#404040',
    lineHeight:.8
  },
}
