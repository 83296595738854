import React, { Component } from 'react';

export default class HelpFaq extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
componentDidMount() {
  window.scrollTo(0, 0)
}
  render() {
    return (
      <div style={styles.container} className="mainContainer">
        <div className="topTitle">Help & FAQ</div>
          <div className="contentBody">
            <div className="contentSectionWrap extraBottomMargin">
              <div className="sub1">Account Restrictions</div>
              <p className="bodyp">
              <b>Q: When I submit a place, I get an error saying I'm not allowed to do so. What's going on?</b>
              </p>
              <p className="bodyp">
              <b>A:</b> Your account has been restricted from adding Places because you've violated one of Doug's rules. Did you submit the same place multiple times? Are you spamming Doug? Did you read the <a style={{cursor:'pointer'}} href="/community-guidelines">Community Guidelines?</a>
              </p>
              <p className="bodyp">
              To ask for the restriction to be lifted, email us <a href="mailto:info@douglovesyou.com?subject=Account Restricted" style={{cursor:'pointer'}}>here.</a>
              </p>
              <p className="bodyp">
              Please include your username in the body of your email.
              </p>
            </div>

          </div>
      </div>
    )
  }
}


const styles= {
  imageWrap : {
    flex:1,
    display:'flex',
    maxWidth:1280,
    margin:'auto',
    paddingTop:'55%',
    borderRadius:5
  },
  image : {
    width:'100%',
    borderRadius:5
  },
  section : {
  },
  container : {
    maxWidth:1000,
    margin:'auto',
    paddingLeft:15,
    paddingRight:15,
    paddingBottom:135,
    justifyContent:'flex-start',
    alignItems:'flex-start'
  },
  sectionHeader : {
    fontFamily: 'Source Serif Pro',
    fontSize:140,
    fontWeight:'bold',
    letterSpacing:-5,
    color:'#404040',
    lineHeight:.8
  },
}
