import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { Container } from 'react-bootstrap';
import Home from './components/Home';
import Other from './components/Other'
import Navigation from './Navigation';
import Footer from './components/Footer';
import Privacy from './components/Privacy';
import HowToDoug from './components/HowToDoug';
import Guidelines from './components/CommunityGuidelines';
import GetDoug from './components/GetDoug';
import HelpFaq from './components/HelpFaq';
import DougForBusiness from './components/DougForBusiness';
import EmailSignup from './components/EmailSignup';
import PlaceDetail from './components/PlaceDetail';
import DeleteMyAccount
 from "./components/Delete";
function App() {
  return (
      <>
        <Router>
            <Navigation/>
            <Switch>
               <Route path='/other' component={Other}/>
               <Route path='/privacy' component={Privacy}/>
               <Route path='/howtodoug' component={HowToDoug}/>
               <Route path='/community-guidelines' component={Guidelines}/>
               <Route path='/getdoug' component={Home}/>
               <Route path='/help-faq' component={HelpFaq}/>
               <Route path='/get-doug' component={GetDoug}/>
               <Route path='/doug-for-business' component={DougForBusiness}/>
               <Route path='/deletemyaccount' component={DeleteMyAccount}/>
               <Route path='/' component={Home}/>
            </Switch>
            <Footer />
            <EmailSignup/>
        </Router>
      </>
  );
}

export default App;
