import React, { Component } from 'react';
import { Navbar, Nav, Container, Row, Col, Button, Input } from 'react-bootstrap';
import axios from 'axios';
import Lottie from 'react-lottie';

export default class FooterEmailSignup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open:false,
      email:'',
      submitting:false,
      success:false
    }
  }
  componentDidMount() {

  }
  submitEmail = () => {
    this.setState({submitting:true})
    axios({
        method:'POST',
        url: 'https://' + process.env.REACT_APP_EMAILFORMAPI + '/emails/addtolist/',
        data: {
          email:this.state.email
        }
    }).then(result=>{
      this.setState({
        submitting:false,
        success:true
      })
      localStorage.setItem('dougstoppopup', true)
    }).catch(error=>{
      this.setState({submitting:false,error:true})
    })

  }
  render() {
    return (
      <div>
      <div style={{
      }}
      className="footerEmailWrap"

      >
      <input style={{
        height:40,
        borderRadius:5,
        fontSize:20,
        paddingLeft:5
        }}
      placeholder="signup for news"
      type="email"
      value={this.state.email}
      onChange={(e)=>{
        this.setState({
          email:e.target.value
        })
      }}
      />
      <Button variant="info" style={{
        color:'white',
        fontFamily:'Rubik Mono One',
      }}
      onClick={()=>{
        this.submitEmail()
      }}
      className="footerEmailButton"
      >Sign Up</Button>
      </div>
      {this.state.success ? (
        <div style={{fontSize:12,marginTop:5}}>thanks for signing up!</div>

      ) : this.state.error ? (
        <div style={{fontSize:12,marginTop:5}}>sorry, there was an error. please try again.</div>
      ) : null}
      </div>
    )
  }
}
