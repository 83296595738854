import React, { Component } from 'react';
import { Form, Button, Row, Col, Modal } from 'react-bootstrap';
import axios from 'axios';
import States from './States';
import Lottie from 'react-lottie';

function parseReverseGeo(geoData) {
  // debugger;
  let locality, region, country, postcode, returnStr;
  if (geoData.context) {
    geoData.context.forEach((v, i) => {
      if (v.id.indexOf("place") >= 0) {
        locality = v.text;
      }
      if (v.id.indexOf("postcode") >= 0) {
        postcode = v.text;
      }
      if (v.id.indexOf("region") >= 0) {
        region = v.short_code;
      }
      if (v.id.indexOf("country") >= 0) {
        country = v.text;
      }
    });
  }
  if (postcode && region && country) {
    returnStr = `${geoData.address} ${
      geoData.text
    }, ${locality} ${region} ${postcode}, ${country}`;
  } else {
    returnStr = geoData.place_name;
  }
  return {
    number: geoData.address,
    address: geoData.text,
    locality,
    region,
    postcode,
    country,
    concat: returnStr,
    complete: geoData.place_name
  };
}



export default class DougForBusiness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stickerAddress:'',
      stickerCity:'',
      stickerState:'',
      stickerZip:'',
      stickerModalVisible:false
    }
  }
componentDidMount() {
  window.scrollTo(0, 0)
}
requeststickers = (e) => {
  e.preventDefault();
  console.log(e.target[0].value);
    // formDataObj = Object.fromEntries(formData.entries())
    // console.log(formDataObj)
    var contactForm = {
      personName:e.target[0].value,
      email:e.target[1].value,
      phone:e.target[2].value,
      businessName:e.target[3].value,
      streetAddress:this.state.stickerAddress,
      city:this.state.stickerCity,
      state:this.state.stickerState,
      zip:this.state.stickerZip,
      requestType:'stickerRequest',
      message:e.target[8].value
    }
    console.log('THE CONTACT FORM is',contactForm)
    this.setState({stickerSubmittingsubmitting:true})
    axios({
        method:'POST',
        url: 'https://' + process.env.REACT_APP_EMAILFORMAPI + '/forms/createform/',
        data: {
          contactForm
        }
    }).then(result=>{
      console.log('success')
      this.setState({
        stickerSubmitting:false,
        stickerModalVisible:true
      })
    }).catch(error=>{
      console.log('error',error)
      this.setState({
        stickerSubmitting:false
      })
      alert('There was a problem requesting your stickers. If this keeps happening, please email us directly at help@douglovesyou.com')

    })
    console.log(contactForm)
}
updateStickerAddress = (e) => {
  console.log(e.target.value)
  this.setState({
    stickerAddress : e.target.value
  })
}

placeSearch(text) {
  if(text=='') {
    this.setState({
      geoLookup:[]
    })
    return;
  }

  axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + text + '.json', {
    params: {
      access_token : process.env.REACT_APP_MAPBOX_TOKEN,
      cachebuster : '1578867295345',
      autocomplete: false,
      types:'address,poi',
      country:'US'
    }
  })

  .then(result=>{
    this.setState({
      geoLookup:result.data
    })
  })
  .catch(error=>{
    console.log('ERROR', error)
  })

}
parseFeature = (feature) => {
  console.log(feature);
 var parsed = parseReverseGeo(feature);
 console.log(parsed.number + ' ' + parsed.address);
 console.log(parsed.locality + ', ' + parsed.region + ' ' + parsed.postcode);
 this.setState({
   geoLookup:null,
   stickerAddress:(parsed.number + ' ' + parsed.address),
   stickerCity:parsed.locality,
   stickerState:parsed.region.slice(-2),
   stickerZip:parsed.postcode
 })
}

  render() {
    console.log(this.state)
    return (
      <div style={styles.container} className="mainContainer">
        <div className="topTitle">Doug for Business</div>
          <div className="contentBody">
            <div className="contentSectionWrap extraBottomMargin">
              <p className="bodyp">
              <br/>Doug is changing the relationship between Businesses and their Customers. On existing platforms, it's too easy for this relationship to become adversarial.
              </p>
              <p className="bodyp">
              <br/>Doug is curated, unclutted, and works on a positive feedback loop. We build Business tools that put you in charge of your profile and your customer relationships.
              </p>
              <p className="bodyp">
              <br/>User generated content means less work maintaining your social media presence - let your customers take the great pictures - Doug will help you share them in your marketing materials and online media.
              </p>
              <p className="bodyp">
              <br/>Doug is a focused channel where the business is always the subject. No more competing with cat pictures.
              </p>


            </div>

            <div className="contentSectionWrap extraBottomMargin">
              <div className="sub1">Claim your business</div>
              <p className="bodyp">
              <br/>To claim and take complete control of your Doug busines profile, please email us at help@douglovesyou.com. <br/><br/>Please include the name of your business and the best way to get in touch with you. We will be back with you within 24 hours!
              </p>
            </div>
            <div className="contentSectionWrap extraBottomMargin">
              <div className="sub1">Add your business to Doug</div>
              <p className="bodyp">
              <br/>To add your business to Doug, please email us at help@douglovesyou.com. <br/><br/>Please include the name of your business and the best way to get in touch with you. We will be back with you within 24 hours!
              </p>
            </div>
            <div className="contentSectionWrap extraBottomMargin">
              <div className="sub1">I need stickers!</div>
              <p className="bodyp">
              <br/>Please put Doug stickers up in your Business! Order a sticker pack, free of charge:
              </p>
              <Form onSubmit={this.requeststickers}>
                <Form.Group className="mb-3" controlId="stickerName">
                  <Form.Label>Your Name</Form.Label>
                  <Form.Control type="text" placeholder="contact name" required/>
                  <Form.Control.Feedback type="invalid">
                  Please tell us your name.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="stickerEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" required/>
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                  Please tell us your email so we can reach you.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="stickerPhone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type="phone" placeholder="Enter phone" required/>
                  <Form.Text className="text-muted">
                    We'll never share your phone number with anyone else.
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                  Please tell us your phone so we can reach you.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="stickerBusinessName">
                  <Form.Label>Business Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Business name" required/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="stickerBusinessAddress">
                  <Form.Label>Street Address</Form.Label>
                  <Form.Control type="text"
                  placeholder="Type to search"
                  value={this.state.stickerAddress}
                  onChange={(text)=>{
                    this.setState({stickerAddress:text.target.value})
                    this.text = text.target.value;
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(()=>this.placeSearch(this.text),500);
                  }}
                  autocomplete="off"
                  required
                  />
                  <Form.Text className="text-muted">
                    Type to search for your shipping address.
                  </Form.Text>
                  {this.state.geoLookup &&
                    <div style={{
                      position:'absolute',
                      backgroundColor:'white'
                    }}>
                      {this.state.geoLookup.features?.map(feature=>{
                        return (
                          <div onClick={()=>{
                            this.parseFeature(feature);
                          }}
                          style={{
                            cursor:'pointer',
                            backgroundColor:'#dedede',
                            borderRadius:5,
                            margin:10
                          }}
                          >{feature.place_name}</div>
                        )
                      })}
                    </div>
                  }
                </Form.Group>
                <Row>
                <Col>
                <Form.Group className="md-3" controlId="stickerCity">
                  <Form.Label>City</Form.Label>
                  <Form.Control type="text" placeholder="Enter Business name"
                  value={this.state.stickerCity}
                  onChange={(e)=>{this.setState({
                    stickerCity:e.target.value
                  })}}
                  required
                   />
                </Form.Group>
                </Col>
                <Col>
                <Form.Group className="md-3" controlId="stickerState">
                  <Form.Label>State</Form.Label>
                  <Form.Select
                  value={this.state.stickerState}
                  onChange={(e)=>{
                    this.setState({
                      stickerState:e.target.value
                    })
                  }}
                  required
                  >
                  <States/>
                  </Form.Select>
                </Form.Group>
                </Col>
                <Col>
                <Form.Group className="md-3" controlId="StickerZip">
                  <Form.Label>Zip</Form.Label>
                  <Form.Control type="text" placeholder="Enter Business name"
                  value={this.state.stickerZip}
                  onChange={(e)=>{
                    this.setState({
                      stickerZip:e.target.value
                    })
                  }}
                  required
                   />
                </Form.Group>
                </Col>

                </Row>
                <Row>
                <Form.Group className="mb-3" controlId="stickerBusinessName" style={{marginTop:25}}>
                  <Form.Label>Additional Info</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="Anything else we need to know?(optional)" />
                </Form.Group>
                </Row>
                {this.state.stickerSubmitting ? (
                  <Lottie options={{
                    loop:true,
                    autoplay:true,
                    animationData:require('../assets/lottie/loading-state.json'),
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice'
                    }
                  }}
                  height={50}
                  width={50}
                  />

                ) : (
                  <Button variant="info" type="submit" style={{color:'white',marginTop:10}}>
                  Submit
                  </Button>

                )}
              </Form>
            </div>
          </div>
          <MyVerticallyCenteredModal
          show={this.state.stickerModalVisible}
          onHide={() => this.setState({
            stickerModalVisible:false
          })}
          />
      </div>
    )
  }
}


const styles= {
  imageWrap : {
    flex:1,
    display:'flex',
    maxWidth:1280,
    margin:'auto',
    paddingTop:'55%',
    borderRadius:5
  },
  image : {
    width:'100%',
    borderRadius:5
  },
  section : {
  },
  container : {
    maxWidth:1000,
    margin:'auto',
    paddingLeft:15,
    paddingRight:15,
    paddingBottom:135,
    justifyContent:'flex-start',
    alignItems:'flex-start'
  },
  sectionHeader : {
    fontFamily: 'Source Serif Pro',
    fontSize:140,
    fontWeight:'bold',
    letterSpacing:-5,
    color:'#404040',
    lineHeight:.8
  },
}

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="stickerModal"
      contentClassName="stickerModalClass"
    >
      <Modal.Body>
        <h2>Your stickers are on the way!</h2>
        <p>
        You will receive a confirmation email when your stickers ship. If you have any questions or issues, please contact us at help@douglovesyou.com.
        </p>
        <Button style={{color:'white'}} variant="info" onClick={props.onHide}>Close</Button>

      </Modal.Body>
    </Modal>
  );
}
