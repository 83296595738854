import React, { Component } from 'react';

export default class Guidelines extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
componentDidMount() {
  window.scrollTo(0, 0)
}
  render() {
    return (
      <div style={styles.container} className="mainContainer">
        <div className="topTitle">Community Guidelines</div>
          <div className="contentBody">
            <div className="contentSectionWrap">
              <div className="sub1">Overview</div>
              <p className="bodyp">
              Doug is a network of communities built around places people love in their lives. A place can be a business, a park, a community rec center or garden. In building or participating in a community, Doug users support their favorite places in the world, and help other users to discover them.
              </p>
              <p className="bodyp">
              Communities on Doug should create a sense of belonging for all those who love a business, and for the users they attract.
              </p>
              <p className="bodyp">
              Everyone on Doug should have a sense of privacy and safety, so please respect the privacy and safety of others.
              </p>
            </div>
            <div className="contentSectionWrap">
              <div className="sub1">Doug Basics</div>
              <p className="bodyp">
              The fundamental purposes of Doug are to:
              <ol className="bodyList">
                <li>support your favorite businesses and</li>
                <li>help others to discover them.</li>
              </ol>
              </p>
            </div>
            <div className="contentSectionWrap">
              <div className="sub1">Places are defined by their Vibes</div>
              <p className="bodyp">
              Every Place on Doug is defined by its community of users through the Vibes given by these users. The culture of each community is driven and enforced by the general laws of Doug. Communities are moderated by the Doug team and selected community members. When interacting with a community, please abide by the following rules:
              </p>
            </div>
            <div className="contentSectionWrap">
              <div className="sub1">Rule 1</div>
              <p className="bodyp">
	             We are all people. Doug focuses on small and independent businesses owned by real people. There are no corporate chains on Doug. What you say about a Place and how you say it can impact the business’s bottom line. Choose your words wisely and always remember that our primary mission is to support places we love.
              </p>
            </div>
            <div className="contentSectionWrap">
              <div className="sub1">Rule 2</div>
              <p className="bodyp">
               No bad Vibes. Doug is a positive place. Vibes are not reviews, they are indicators of a positive experience and/or feeling.
              </p>
            </div>
            <div className="contentSectionWrap">
              <div className="sub1">Rule 3</div>
              <p className="bodyp">
               If you don’t like a Place, ignore it.
              </p>
            </div>

            <div className="contentSectionWrap">
              <div className="sub1">Rule 4</div>
              <p className="bodyp">
	             Make real content. Use Vibes and photos to describe a real experience you have at a Place. Show people what it’s all about. Do not cheat a community by posting false content or spamming a Vibe thread.
              </p>
            </div>
            <div className="contentSectionWrap">
              <div className="sub1">Rule 5</div>
              <p className="bodyp">
              Vibes cannot be obscene, harassing, sexually explicit, combative, dehumanizing, or belittling in any way.
              </p>
            </div>
            <div className="contentSectionWrap">
              <div className="sub1">Rule 6</div>
              <p className="bodyp">
              You don’t have to use your real name to use Doug, but don’t impersonate an individual or an entity in a misleading or deceptive manner.
              </p>
            </div>
            <div className="contentSectionWrap">
              <div className="sub1">Rule 7</div>
              <p className="bodyp">
	             Ensure people have predictable experiences on Doug by properly using vibes and correctly labeling content and communities.
              </p>
            </div>
            <div className="contentSectionWrap">
              <div className="sub1">Rule 8</div>
              <p className="bodyp">
	             Doug reserves the right to remove any and all content whatsoever, at any time.
              </p>
            </div>
            <div className="contentSectionWrap">
              <p className="bodyp">
              If you violate these community guidelines, we will ask you to stop whatever the offending behavior is. Continued violation of any of the Community Guidlines will lead to a temporary or permanent suspension of your account.
              </p>
            </div>
          </div>
      </div>
    )
  }
}



const styles= {
  imageWrap : {
    flex:1,
    display:'flex',
    maxWidth:1280,
    margin:'auto',
    paddingTop:'55%',
    borderRadius:5
  },
  image : {
    width:'100%',
    borderRadius:5
  },
  section : {
  },
  container : {
    maxWidth:1000,
    margin:'auto',
    paddingLeft:15,
    paddingRight:15,
    paddingBottom:135,
    justifyContent:'flex-start',
    alignItems:'flex-start'
  },
  sectionHeader : {
    fontFamily: 'Source Serif Pro',
    fontSize:140,
    fontWeight:'bold',
    letterSpacing:-5,
    color:'#404040',
    lineHeight:.8
  },
  contentBody : {
    textAlign:'left',
    marginTop:25
  },
  sub1 : {


  }
}
